(function($) {
    const fn = $(".comp_career_backend");

    if (fn.length) {
        let commandlist = [ /*Can be populated with various methods*/
            ["/help", "Show commands"],
            ["/list", "List of all sections"],
            ["/nav &lt;section&gt;", "Navigate to section"],
            ["/contact", "If you are interested, contact us"],
            ["/kajnsmetke", "Plese do NOT use this!!"],
            ["/clear", "Clear the console"]
        ];
        let previouscommands = [];
        let currentcommand = 0;
        let pages = fn.find(".wrp_puzzle_inner").data("text");
        let pageindex = ["we_offer", "we_require"];
        let currentpage;

        /*
           Custom Text Syntax
           Links:
              [URLPATH](NAME) - regular
              [^URLPATH](NAME) - open in new tab

           Styles:
              *TEXT* - bold text
              E! - Text is an error/notification
              A! - spaces are converted to non-breaking spaces (it's for ascii art - after all, this is a text based website)
        */

        function init() {
            setInterval(time);
            log("NewLogic", "_____ _____ __ __ _____");
            log("NewLogic", "New Logic kariéra - detail pozice /backend");
            log("NewLogic", "");
            log("NewLogic", "Hledáme PHP vývojáře");
            log("NewLogic", "");
            log("NewLogic", "Nyní jste na stránce: [^www.newlogic.cz/kariera/detail-backendak/](*kariera.newlogic.cz/detail-backendak/*)");
            log("NewLogic", "Chcete se dozvědet více? Veškeré info naleznete pomocí příkazů, můžete začít s příkazem '/help'");
        }

        function log(name, information) {
            let d = new Date();
            let hours = ((d.getHours() < 10) ? "0" : "") + d.getHours();
            let minutes = ((d.getMinutes() < 10) ? "0" : "") + d.getMinutes();
            let seconds = ((d.getSeconds() < 10) ? "0" : "") + d.getSeconds();
            let colour = "mod--color-1";
            let postcolour = "";

            switch (name[0]) {
                case "!":
                    postcolour = " mod--color-important";
                    name = name.substr(1);
                    break;
            }
            switch (name) {
                case "NewLogic":
                    colour = "mod--color-newlogic";
                    break;
                case "Client":
                    colour = "mod--color-client";
                    break;
                case "User":
                    colour = "mod--color-user";
                    postcolour = " mod--color-self";
                    break;
            }
            if (information[0] === "A" && information[1] === "!") {
                information = information.substr(2);
                information = information.replace(/ /g, '\u00A0');
            }
            if (information[0] === "E" && information[1] === "!") {
                information = information.substr(2);
                postcolour = " mod--color-important";
            }

            while (information.indexOf("](") >= 0) { //URL parser

                let NAMEregExp = /\(([^)]+)\)/;
                let uname = NAMEregExp.exec(information)[1];

                let URLregExp = /\[([^)]+)\]/;
                let url = URLregExp.exec(information)[1];
                let newpage = false;
                if (url[0] === "^") {
                    newpage = true;
                    url = url.substr(1);
                }
                let start = information.indexOf("[");
                let end = information.indexOf(")");
                if (newpage) {
                    information = information.replace(information.substring(start, end + 1), "").splice(start, 0, '<a href="' + url + '" target="_blank">' + uname + '</a>');
                } else {
                    information = information.replace(information.substring(start, end + 1), "").splice(start, 0, '<a href="' + url + '">' + uname + '</a>');
                }
                //information = '<a href="' + url + '">' + uname + '</a>'; //working

            }
            let tobold = true;
            let boldnumber = 0;
            for (let i = 0; i < information.length; i++) {
                if (information[i] === "*" && information[i - 1] !== "*" && information[i + 1] !== "*") {
                    boldnumber++;
                }
            }
            while (information.indexOf("*") >= 0) { //Bold parser
                let pos = information.indexOf("*");
                information = information.replace("*", "");
                if (tobold) {
                    information = information.splice(pos, 0, '<b>');
                } else {
                    information = information.splice(pos, 0, '</b>');
                }
                tobold = !tobold;
                if (tobold && boldnumber <= 1) {
                    break;
                }
                //information = '<a href="' + url + '">' + uname + '</a>'; //working
            }
            let tounderline = true;
            let underlinenumber = 0;
            for (let i = 0; i < information.length; i++) {
                if (information[i] === "*" && information[i - 1] !== "*" && information[i + 1] !== "*") {
                    underlinenumber++;
                }
            }
            while (information.indexOf("**") >= 0) { //Bold parser
                let pos = information.indexOf("**");
                information = information.replace("**", "");
                if (tounderline) {
                    information = information.splice(pos, 0, '<u>');
                } else {
                    information = information.splice(pos, 0, '</u>');
                }
                tounderline = !tounderline;
                if (tounderline && underlinenumber <= 1) {
                    break;
                }
                //information = '<a href="' + url + '">' + uname + '</a>'; //working
            } /**/
            $(".stream").append('<div class="line">' +
                '<p class="time">[' + hours + ":" + minutes + ":" + seconds + ']</p>' +
                '<p class="name ' + colour + '">' + name + '</p>' +
                '<p class="information' + postcolour + '">' + information + '</p>' +
                '</div>');
        }
        let timestring = "";
        function time() {
            let d = new Date();
            let hours = d.getHours();
            let minutes = d.getMinutes();
            let seconds = d.getSeconds();
            if (hours < 10) {
                hours = "0" + hours;
            }
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            let temptimestring = "[" + hours + ":" + minutes + ":" + seconds + "]";
            if (temptimestring !== timestring) {
                timestring = temptimestring;
                $(".editline .time").text(timestring);
            }
        }

        let ctrldown = false;
        $(".editline .edit").keydown(function(e) {
            let text = $(this).text();
            if (e.originalEvent.keyCode === 13 && text !== "" && !ctrldown) {
                let commands = text.split(' ');
                if (commands[0] === "help") {
                    text = "/" + text;
                }
                $(this).text("");
                log("User", text);

                previouscommands[currentcommand] = text;
                currentcommand = previouscommands.length;
                // $(".editline .edit").keydown(35);
                cmd(commands[0], text, commands);
                /*Add mod commands*/
                //modcmd(commands[0], text, commands);
                /*Add mod commands*/

            }
            if (e.originalEvent.keyCode === 38) { //up
                if (currentcommand > 0) {
                    currentcommand--;
                    $(this).text(previouscommands[currentcommand]);
                }
            }
            if (e.originalEvent.keyCode === 40) { //down

                if (currentcommand < previouscommands.length) {
                    currentcommand++;
                    $(this).text(previouscommands[currentcommand]);
                }
            }
        });

        let output = "";

        function cmd(command, words, word) {
            switch (word[0]) {
                case "/help":
                case "help":
                    for (let i = 0; i < commandlist.length; i++) {
                        output = commandlist[i][0] + " : " + commandlist[i][1];

                        log("Client", output);
                    }
                    break;
                case "/clear":
                    $(".stream").text("");
                    break;
                case "/nav":
                    if ($.inArray(word[1], pageindex) >= 0) {
                        currentpage = word[1];
                        log("NewLogic", "Showing info about " + currentpage);
                        loadpage($.inArray(word[1], pageindex));
                    } else {
                        log("Client", "'" + word[1] + "' does not exist.");
                    }
                    break;
                case "/list":
                    $.each(pageindex, function(id, content) {
                        log("Client", "> " + content);
                    });
                    break;
                case "/contact":
                    log("NewLogic", "Redirecting to application form in 2 seconds");
                    setTimeout(function(){
                        $('html, body').animate({
                            scrollTop: $(".comp_application").offset().top
                        }, 500);
                    },2000);
                    break;
                case "/kajnsmetke":
                    log("NewLogic", "You should have not do this. Fly you fool!");
                    setTimeout(function(){
                        body.addClass("mod--kajnsmetke");
                    },1000);
                    setTimeout(function(){
                        body.removeClass("mod--kajnsmetke");
                    },61000);
                    break;
                default:
                    output = "Unrecognised command '" + word[0] + "'.";
                    log("Client", output);
            }

            fn.find(".wrp_puzzle_inner").scrollTop(fn.find(".wrp_puzzle_inner").height());
        }

        function loadpage(i) {
            $.each(pages[i], function(id, content) {
                if (content !== pageindex[i]) {
                    log("NewLogic", content);
                }
            });
        }

        String.prototype.splice = function(idx, rem, str) {
            return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
        };
        init();
    }
})(jQuery);