//____ ANCHOR LINKS ANIMATIONS ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
function fn_nl_lib_anchor() {
    let hash = window.location.hash;

    $.fn.anchor_anim = function (duration) {
        let elm = $(this),
            offset = 0;
        if (elm.is("[data-anchor-offset]") && win.width() > 960) {
            if (isNaN(elm.data("anchor-offset"))) {
                offset = $(elm.data("anchor-offset")).innerHeight();
            } else {
                offset = elm.data("anchor-offset");
            }
        }
        $('html, body').animate({
            scrollTop: elm.offset().top - offset
        }, duration);
    };

    doc.on("click", "[data-anchor]", function(e) {
        let id = $(this).attr("href");

        if ($(this).filter("[data-anchor-href]").length) {
            id = $(this).data("anchor-href");
        }

        let anchor_elm = $("[data-anchor-elm="+id.replace('#','')+"]");

        if (anchor_elm.length) {
            e.preventDefault();

            if (!($(this).is("[data-anchor-mobile]") && win.width() > 960)) {
                anchor_elm.anchor_anim(500);
                if($(this).data("anchor") === "hash") {
                    window.location.hash = id;
                }
            }
        }
    });

    $("[data-anchor-elm]").each(function(){
        let elm = $(this);
        if(hash && elm.data("anchor-elm") === hash.replace('#','')) {
            cssLoaded(function(){
                elm.anchor_anim(0);
            });
        }
    });
}