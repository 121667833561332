let cdnjs = {
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "rellax": "https://cdnjs.cloudflare.com/ajax/libs/rellax/1.4.0/rellax.min.js",
    "headroom": "https://cdnjs.cloudflare.com/ajax/libs/headroom/0.9.4/headroom.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.0.0/js/newlogic-cookieconsent.min.js",
    "autosize": "https://cdnjs.cloudflare.com/ajax/libs/autosize.js/4.0.2/autosize.min.js",
    "prism": "https://cdnjs.cloudflare.com/ajax/libs/prism/1.15.0/prism.min.js",
    "codeflask": "https://cdnjs.cloudflare.com/ajax/libs/CodeFlask.js/1.2.1/codeflask.min.js",
    "sortable": "https://cdnjs.cloudflare.com/ajax/libs/Sortable/1.6.0/Sortable.min.js",
    "flickity": "https://cdnjs.cloudflare.com/ajax/libs/flickity/2.1.2/flickity.pkgd.min.js",
    "typeit": "https://cdnjs.cloudflare.com/ajax/libs/typeit/5.10.7/typeit.min.js",
    "twgl": "https://cdn.jsdelivr.net/npm/twgl.js@4.5.2/dist/4.x/twgl-full.min.js",
    "webgl_m3": "https://webglfundamentals.org/webgl/resources/m3.js"
};