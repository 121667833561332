(function($){
    const fn = $(".comp_career_designer");

    if (fn.length) {
        let puzzle = fn.find(".wrp_comp_puzzle");
        let body = fn.find(".wrp_comp_body");
        
        $.importScript(cdnjs.sortable, function () {
            let mouseX, mouseY;

            fn.find("[data-sortable-from]").each(function(){
               let elm = $(this);
               let group = elm.data("sortable-from");

                Sortable.create(elm[0], {
                    sort: false,
                    group: {
                        name: group,
                        pull: 'clone',
                        put: false
                    },
                    onMove: function(evt, originalEvent) {
                        mouseX = originalEvent.clientX;
                        mouseY = originalEvent.clientY;
                    },
                    animation: 150
                });
            });


            fn.find("[data-sortable-to]").each(function(i){
                let elm = $(this);
                let group = elm.data("sortable-to");

                let svg = elm.find("svg")[0];

                function svgPoint(element, x, y) {

                    let pt = svg.createSVGPoint();
                    pt.x = x;
                    pt.y = y;
                    return pt.matrixTransform(element.getScreenCTM().inverse());

                }

                Sortable.create(elm[0], {
                    sort: false,
                    scroll: false,
                    handle: ".none",
                    group: {
                        name: group,
                        pull: false,
                        put: true
                    },
                    animation: 150,
                    onAdd: function (evt) {
                        let from_color = $(evt.from).find(".icon").css("color");

                        let svgP = svgPoint(svg, mouseX, mouseY);

                        $(evt.item).css({
                            "top": mouseY - elm.offset().top + win.scrollTop(),
                            "left": mouseX - elm.offset().left
                        }).addClass("drag-element--dropped");

                        elm.find(".elm_fill").attr({
                            "cx": Math.round(svgP.x),
                            "cy": Math.round(svgP.y)
                        }).css("fill", from_color).addClass("mod--active");

                        setTimeout(function(){
                            $(evt.item).remove();
                        },1000);

                        if (fn.find("[data-sortable-to] .elm_fill.mod--active").length === 2) {
                            setTimeout(function(){
                                puzzle.slideUp(500);
                                setTimeout(function(){
                                    body.slideDown(500);
                                    window.location.hash = "#detail";
                                    $(".comp_application").find(".elm_body_form").removeClass("mod--disabled").removeAttr("data-dialog");
                                    $('html, body').animate({
                                        scrollTop: body.offset().top
                                    }, 500, "swing", function(){
                                        $(".headroom").removeClass("headroom--pinned").addClass("headroom--unpinned");
                                    });
                                },500);
                            },1000);
                        }
                    }
                });
            });
        });
    }
})(jQuery);