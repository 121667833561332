(function($) {
    const fn = $(".comp_application");

    if (fn.length) {
        fn.on("change", "[data-position-select]", function(){
            let elm = $(this);
            let position = elm.closest("[data-position]").data("position");
            
            if(position) {
                $('[data-oddeleni]').val(position);
            }

            elm.closest(".wrp_comp_body").find(".part_form_application").removeClass(function (index, className) {
                return (className.match (/(^|\s)mod--position-\S+/g) || []).join(' ');
            }).addClass(`mod--position-${position}`);
        });
    }
})(jQuery);