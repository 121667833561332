(function($){
    const fn = $(".comp_visual");
    let video = fn.find("video");

    video.addClass("is--playing");

    if (fn.length) {
        fn.on("click", "[data-visual-audio]", function(){
            let elm = $(this);
            if( video.prop('muted') ) {
                video.prop('muted', false);
                elm.addClass("mod--active");
            } else {
                video.prop('muted', true);
                elm.removeClass("mod--active");
            }
        });

        win.on("scroll", function() {
            if (doc.scrollTop() > fn.height()) {
                if (video.hasClass("is--playing")) {
                    video.removeClass("is--playing");
                    video[0].pause();
                }
            } else {
                if (!video.hasClass("is--playing")) {
                    video.addClass("is--playing");
                    video[0].play();
                }
            }
        });
    }
})(jQuery);
