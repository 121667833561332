var reInitGCaptcha;

(function ($) {
    reInitGCaptcha = function () {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LdytnQUAAAAAA02l5RdoxDw92oVaexhQcCEhlpS").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LdytnQUAAAAAA02l5RdoxDw92oVaexhQcCEhlpS', {action: 'form'})
                .then(function (token) {
                    $('#g-token').val(token);
                });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    }

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function (e) {
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function () {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    $("[data-switch]").nl_lib_switch();

    nl_lib_dialog.init(function () {
        Object.keys(window.lui.lib).forEach(function (key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s[0]).length) {
                window.lui.lib[key](dialog_selector.find(s[0]), s[1])
            }
        });
    });

    $.libInit("[data-switch]", "lib_switch", function (selector) {
        $(selector).nl_lib_switch();
    });

    $.libInit(".part_ui_btn, .part_ui_btn_square", "lib_ripple", function (selector) {
        $(selector).nl_lib_ripple();
    });

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
                formData = new FormData($(this)[0]);

        var submitButton = $(this).find('button[type="submit"]');
        if (submitButton.length) {
            submitButton.attr('disabled', 'disabled');
            submitButton.addClass('mod--loading');
        }

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function () {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }

                    if (submitButton.length) {
                        setTimeout(function () {
                            submitButton.removeAttr('disabled');
                            submitButton.removeClass('mod--loading');
                        }, 3000);
                    }
                });
            }
        });
    });

    doc.on('click', 'a[data-component-ajax]', function (e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if (!url || !url.length) {
            url = $(this).attr('href');
        }

        if (!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload, function () {
                sr.sync();
            });
        });
    });

    if ($(".lib--rellax").length) {
        if (win.width() > 768) {
            $.importScript(cdnjs.rellax, function () {
                new Rellax('.lib--rellax', {
                    center: true
                });
            });
        }
    }
    if ($('textarea').length) {
        $.importScript(cdnjs.autosize, function () {
            cssLoaded(function () {
                autosize($('textarea'));
            });
        });
    }

    let picture = document.createElement('picture'),
            strObj = '' + picture;

    if (strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.importScript(cdnjs.picturefill);
    }

    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }

    window.addEventListener('touchmove', (e) => {
        return;
    }, { passive: false });
})(jQuery);