(function($){
    const fn = $(".comp_career_frontend");

    if (fn.length) {
        let puzzle = fn.find(".wrp_comp_puzzle");
        let body = fn.find(".wrp_comp_body");

        $.importScript(cdnjs.prism, function(){
            $.importScript(cdnjs.codeflask, function(){
                cssLoaded(function(){
                    if (typeof CodeFlask === "undefined") {
                        $("#my-selector").append("<div style='padding: 1em'>Editor is not supported in this browser.</div>")
                    }
                    const flask = new CodeFlask('#my-selector', {
                        language: 'css',
                        defaultTheme: false
                    });

                    let preview = fn.find(".wrp_puzzle_preview");
                    let flex_wrapper = preview.find(".wrapper");
                    let flex_circle = preview.find(".circle");

                    flask.onUpdate((code) => {
                        preview.find("style").remove();
                        preview.append(`<style>${code}</style>`);

                        if (!preview.hasClass("mod--active")) {
                            let flex_wrapper_width = (flex_wrapper.width() / 2) - (flex_circle.width() / 2);
                            let flex_wrapper_height = (flex_wrapper.height() / 2) - (flex_circle.height() / 2);

                            if (Math.round(flex_circle.position().top) === Math.round(flex_wrapper_height) && Math.round(flex_circle.position().left) === Math.round(flex_wrapper_width)) {
                                if (parseInt(flex_circle.position().top) !== 0 && parseInt(flex_circle.position().left) !== 0 && parseInt(flex_wrapper_width) !== 0 && parseInt(flex_wrapper_height) !== 0) {
                                    preview.addClass("mod--active");
                                    setTimeout(function(){
                                        puzzle.slideUp(500);
                                        setTimeout(function(){
                                            body.slideDown(500);
                                            window.location.hash = "#detail";
                                            $(".comp_application").find(".elm_body_form").removeClass("mod--disabled").removeAttr("data-dialog");
                                            $('html, body').animate({
                                                scrollTop: body.offset().top
                                            }, 500, "swing", function(){
                                                $(".headroom").removeClass("headroom--pinned").addClass("headroom--unpinned");
                                            });
                                        },500);
                                    },2500);
                                }
                            }
                        }
                    });
                });
            });
        });
    }
})(jQuery);
