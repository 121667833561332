(function($) {
    const fn = $("#layout_header");
    const fn_nav = $("#layout_nav");

    if (fn.length) {
        $.importScript(cdnjs.touchswipe, function(){
            $('.ssm-nav').slideAndSwipe();
        });

        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });

        let timeout;

        fn_nav.on("click", "[data-nav-href]", function(e) {
            e.preventDefault();

            let elm = $(this);

            clearTimeout(timeout);

            timeout = setTimeout(function(){
                window.location.href = elm.attr("href");
            },500);
        });

        fn_nav.find("a").each(function(i) {
            $(this).parent().css("animation-delay", `${500+(i*300)}ms`)
        })
    }
})(jQuery);