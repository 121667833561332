(function($){
    const fn = $(".comp_team_intro");

    if (fn.length) {
        let slider = fn.find("[data-slider]");

        $.lib_flickity(function(){
            $.importScript(cdnjs.typeit, function() {
                if (slider.length) {

                    let typeit;

                    slider.on('ready.flickity', function() {
                        let elm = slider.find(".elm_item").eq(0).find("[data-typing]");
                        typeit = new TypeIt(elm[0], {
                            strings: elm.data("typing"),
                            speed: 50,
                            autoStart: false
                        })
                    });

                    slider.flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        adaptiveHeight: true,
                        pageDots: false,
                        wrapAround: true,
                        contain: true,
                        autoPlay: 8000,
                        prevNextButtons: false,
                        pauseAutoPlayOnHover: false,
                        draggable: false
                    });

                    slider.on('change.flickity', function( event, index ) {
                        let elm = slider.find(".elm_item").eq(index).find("[data-typing]");
                        if (typeof typeit !== "undefined") {
                            typeit.destroy();
                            elm.html("")
                        }
                        typeit = new TypeIt(elm[0], {
                            strings: elm.data("typing"),
                            speed: 50,
                            autoStart: false
                        })
                    });

                    fn.on("click", ".part_item_team", function(){
                        slider.flickity( 'select', $(this).closest(".col").index() );
                    });

                    fn.on({
                        mouseenter: function () {
                            slider.flickity('pausePlayer');
                        },
                        mouseleave: function () {
                            slider.flickity('unpausePlayer');
                        }
                    }, ".wrp_comp_body");
                }
            });
        });
    }
})(jQuery);
