(function($){
    const fn = $(".comp_career_marketing");

    if (fn.length) {
        let puzzle = fn.find(".wrp_comp_puzzle");
        let body = fn.find(".wrp_comp_body");
        
        $.importScript(cdnjs.sortable, function () {
            fn.find("[data-sortable-from]").each(function(){
               let elm = $(this);
               let group = elm.data("sortable-from");

                Sortable.create(elm[0], {
                    sort: false,
                    group: {
                        name: group,
                        pull: true,
                        put: false
                    },
                    animation: 150
                });
            });

            fn.find("[data-sortable-to]").each(function(){
                let elm = $(this);
                let group = elm.data("sortable-to");

                Sortable.create(elm.children("div")[0], {
                    sort: false,
                    scroll: false,
                    handle: ".none",
                    group: {
                        name: group,
                        pull: false,
                        put: true
                    },
                    animation: 150,
                    onAdd: function (evt) {
                        let count = elm.children("div").children().length;
                        elm.find("canvas").css("transform", `translateY(${80 - (count*12)}%)`);

                        if (fn.find(`[data-sortable-to="ppc"]`).children("div").children().length === fn.find(`[data-sortable-from="ppc"]`).length && fn.find(`[data-sortable-to="social"]`).children("div").children().length === fn.find(`[data-sortable-from="social"]`).length) {
                            setTimeout(function(){
                                puzzle.slideUp(500);
                                setTimeout(function(){
                                    body.slideDown(500);
                                    window.location.hash = "#detail";
                                    $(".comp_application").find(".elm_body_form").removeClass("mod--disabled").removeAttr("data-dialog");
                                    $('html, body').animate({
                                        scrollTop: body.offset().top
                                    }, 500, "swing", function(){
                                        $(".headroom").removeClass("headroom--pinned").addClass("headroom--unpinned");
                                    });
                                },500);
                            },500);
                        }
                    }
                });
            });

            $.importScript("https://cdnjs.cloudflare.com/ajax/libs/paper.js/0.11.5/paper-full.min.js", function(){
                let paper_fluid = function(fluid_x, interval){
                    return `
                        var values = {
                            friction: 0.8,
                            timeStep: 0.01,
                            amount: 15,
                            mass: 2,
                            count: 0
                        };
                        values.invMass = 1 / values.mass;
    
                        var path, springs;
                        var size = view.size * [1.2, 1];
    
                        var Spring = function(a, b, strength, restLength) {
                            this.a = a;
                            this.b = b;
                            this.restLength = restLength || 30;
                            this.strength = strength ? strength : 0.55;
                            this.mamb = values.invMass * values.invMass;
                        };
    
                        Spring.prototype.update = function() {
                            var delta = this.b - this.a;
                            var dist = delta.length;
                            var normDistStrength = (dist - this.restLength) /
                                    (dist * this.mamb) * this.strength;
                            delta.y *= normDistStrength * values.invMass * 0.2;
                            if (!this.a.fixed)
                                this.a.y += delta.y;
                            if (!this.b.fixed)
                                this.b.y -= delta.y;
                        };
    
    
                        function createPath(strength) {
                            var path = new Path({
                                fillColor: '#5c33ae'
                            });
                            springs = [];
                            for (var i = 0; i <= values.amount; i++) {
                                var segment = path.add(new Point(i / values.amount, 0.2) * size);
                                var point = segment.point;
                                if (i == 0 || i == values.amount)
                                    point.y += size.height;
                                point.px = point.x;
                                point.py = point.y;
                                // The first two and last two points are fixed:
                                point.fixed = i < 2 || i > values.amount - 2;
                                if (i > 0) {
                                    var spring = new Spring(segment.previous.point, point, strength);
                                    springs.push(spring);
                                }
                            }
                            path.position.x -= size.width / 4;
    
                            function animate() {
                                var location = path.getNearestLocation({ x: ${fluid_x}, y: 85 });
                                var segment = location.segment;
                                var point = segment.point;
    
                                if (!point.fixed && location.distance < size.height / 4) {
                                    var y = 85;
                                    point.y += (y - point.y) / 6;
                                    if (segment.previous && !segment.previous.fixed) {
                                        var previous = segment.previous.point;
                                        previous.y += (y - previous.y) / 24;
                                    }
                                    if (segment.next && !segment.next.fixed) {
                                        var next = segment.next.point;
                                        next.y += (y - next.y) / 24;
                                    }
                                }
                            }
    
                            setTimeout(function(){
                                animate();
                            },${interval});
    
                            setInterval(function(){
                                animate();
                            },${10000 + interval});
    
                            return path;
                        }
    
                        function onResize() {
                            if (path)
                                path.remove();
                            size = view.bounds.size * [2, 1];
                            path = createPath(0.1);
                        }
    
                        function onMouseDown(event) {
                            console.log(event.point);
                        }
    
                        function onFrame(event) {
                            updateWave(path);
                        }
    
                        function updateWave(path) {
                            var force = 1 - values.friction * values.timeStep * values.timeStep;
                            for (var i = 0, l = path.segments.length; i < l; i++) {
                                var point = path.segments[i].point;
                                var dy = (point.y - point.py) * force;
                                point.py = point.y;
                                point.y = Math.max(point.y + dy, 0);
                            }
    
                            for (var j = 0, l = springs.length; j < l; j++) {
                                springs[j].update();
                            }
                            path.smooth({ type: 'continuous' });
                        }
            `};
                fn.prepend(`
                    <script type="text/paperscript" canvas="myCanvas">
                      ${paper_fluid(41,0)}
                    </script>
                    <script type="text/paperscript" canvas="myCanvas2">
                      ${paper_fluid(100,1000)}
                    </script>
                `);
            });

        });
    }
})(jQuery);