(function($){
    const fn = $(".comp_career_management");

    if (fn.length) {
        let puzzle = fn.find(".wrp_comp_puzzle");
        let body = fn.find(".wrp_comp_body");

        fn.on("change", "[data-checkbox-team]", function(){
            let elm = $(this);
            let name = elm.attr("name");
            let nav = fn.find(`[data-position="${name}"]`);
            let number = nav.find("[data-number]");
            let checked = fn.find(`[name="${name}"]:checked`).length;

            if (checked > 0) {
                number.attr("data-number", checked);
                nav.addClass("mod--number-active");
            } else {
                nav.removeClass("mod--number-active");
            }
        });

        let timeout;

        fn.on("click", "[data-confirm-team]", function(){
            let elm = $(this);
            let notice = elm.closest(".elm_inner_bottom").find(".part_ui_alert");

            if (fn.find(`[name="frontend"]:checked`).length > 0 && fn.find(`[name="backend"]:checked`).length > 0 && fn.find(`[name="marketing"]:checked`).length > 0 && fn.find(`[name="designer"]:checked`).length > 0) {
                setTimeout(function(){
                    puzzle.slideUp(500);
                    setTimeout(function(){
                        body.slideDown(500);
                        window.location.hash = "#detail";
                        $(".comp_application").find(".elm_body_form").removeClass("mod--disabled").removeAttr("data-dialog");
                        $('html, body').animate({
                            scrollTop: body.offset().top
                        }, 500, "swing", function(){
                            $(".headroom").removeClass("headroom--pinned").addClass("headroom--unpinned");
                        });
                    },500);
                },500);
            } else {
                notice.addClass("mod--active");
                clearTimeout(timeout);
                timeout = setTimeout(function(){
                    notice.removeClass("mod--active");
                },3000);
            }
        })
    }
})(jQuery);