(function($) {
    const fn = $(".comp_career");

    if (fn.length) {
        let puzzle = fn.find(".wrp_comp_puzzle");
        let body = fn.find(".wrp_comp_body");

        if (window.location.hash === "#detail") {
            puzzle.hide();
            body.show();
            $(".comp_application").find(".elm_body_form").removeClass("mod--disabled").removeAttr("data-dialog");
        }
    }
})(jQuery);